/* ========== 娱乐区游戏大厅接口定义 ========== */
import request, { MarkcoinRequest } from '@/plugins/request'
import {
  IAreaTransferRecordReq,
  IAreaTransferRecordResp,
  IAreaUserTransferReq,
  IAreaUserTransferResp,
  IAreaUserBalanceReq,
  IAreaUserBalanceResp,
  IAreaUserBalanceCollectReq,
  IAreaUserBalanceCollectResp,
  IAreaUserGameRecordReq,
  IAreaUserGameRecordResp,
  IAreaUserTransferRecordReq,
  IAreaUserTransferRecordResp,
  IAreaGroupTypeReq,
  IAreaGroupTypeResp,
  IAreaPreferenceReq,
  IAreaPreferenceResp,
  IAreaRaHallGameReq,
  IAreaRaHallGameResp,
  IAreaGameRaHallGameReq,
  IAreaGameRaHallGameResp,
  IAreaGroupListReq,
  IAreaGroupListResp,
  IARebateDetailedReq,
  IARebateDetailedResp,
  IARebateBalancedReq,
  IARebateBalancedResp,
  IAbalanceCollectResp,
  IAbalanceCollectReq,
  IAmemberBaseWalletCoinReq,
  IAmemberBaseWalletCoinResp,
  IAmemberBaseCoinWalletReq,
  IAmemberBaseCoinWalletResp,
} from '@/typings/api/entertainment-area'

/**
 * 划转记录
 * https://yapi.nbttfc365.com/project/83/interface/api/19074
 */
export const getTransferRecord: MarkcoinRequest<IAreaTransferRecordReq, IAreaTransferRecordResp> = params => {
  return request({
    path: '/v1/ra-hall/transferRecord',
    method: 'GET',
    params,
  })
}

/**
 * 手动划转资金
 * https://yapi.nbttfc365.com/project/83/interface/api/19094
 */
export const postUserTransfer: MarkcoinRequest<IAreaUserTransferReq, IAreaUserTransferResp> = data => {
  return request({
    path: '/v1/ra-hall/user/transfer',
    method: 'POST',
    data,
  })
}

/**
 * 用户资产
 * https://yapi.nbttfc365.com/project/83/interface/api/19099
 */
export const getUserBalance: MarkcoinRequest<IAreaUserBalanceReq, IAreaUserBalanceResp> = params => {
  return request({
    path: '/v1/ra-hall/user/balance',
    method: 'GET',
    params,
  })
}

/**
 * 资金归集
 * https://yapi.nbttfc365.com/project/83/interface/api/19104
 */
export const getUserBalanceCollect: MarkcoinRequest<
  IAreaUserBalanceCollectReq,
  IAreaUserBalanceCollectResp
> = params => {
  return request({
    path: '/v1/ra-hall/user/balanceCollect',
    method: 'GET',
    params,
  })
}

/**
 * 游戏记录
 * https://yapi.nbttfc365.com/project/83/interface/api/19079
 */
export const getRaHallGameRecord: MarkcoinRequest<IAreaUserGameRecordReq, IAreaUserGameRecordResp> = params => {
  return request({
    path: '/v1/ra-hall/gameRecord',
    method: 'GET',
    params,
  })
}

/**
 * 划转记录
 * https://yapi.nbttfc365.com/project/83/interface/api/19074
 */
export const getRaHallTransferRecord: MarkcoinRequest<
  IAreaUserTransferRecordReq,
  IAreaUserTransferRecordResp
> = params => {
  return request({
    path: '/v1/ra-hall/transferRecord',
    method: 'GET',
    params,
  })
}

/**
 * 游戏馆列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19069
 */
export const getRaHallGroupType: MarkcoinRequest<IAreaGroupTypeReq, IAreaGroupTypeResp> = params => {
  return request({
    path: '/v1/ra-hall/group/type',
    method: 'GET',
    params,
  })
}

/**
 * 获取用户偏好设置
 * https://yapi.nbttfc365.com/project/83/interface/api/19089
 */
export const getRaHallUserPreference: MarkcoinRequest<IAreaPreferenceReq, IAreaPreferenceResp> = params => {
  return request({
    path: '/v1/ra-hall/user/preference',
    method: 'GET',
    params,
  })
}

/**
 * 用户偏好设置
 * https://yapi.nbttfc365.com/project/83/interface/api/19084
 */
export const postRaHallUserPreference: MarkcoinRequest<IAreaPreferenceResp, IAreaPreferenceReq> = data => {
  return request({
    path: '/v1/ra-hall/user/preference',
    method: 'POST',
    data,
  })
}

/**
 * 游戏类型列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19064
 */
export const postRaHallGameType: MarkcoinRequest<IAreaRaHallGameReq, IAreaRaHallGameResp> = params => {
  return request({
    path: '/v1/ra-hall/game/type',
    method: 'GET',
    params,
  })
}

/**
 * 报表记录
 * https://yapi.nbttfc365.com/project/83/interface/api/19064
 */
export const getRecordStatistics: MarkcoinRequest<IAreaGameRaHallGameReq, IAreaGameRaHallGameResp> = params => {
  return request({
    path: '/v1/ra-hall/gameRecord/statistics',
    method: 'GET',
    params,
  })
}

/**
 * 游戏馆列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19029
 */
export const getGroupList: MarkcoinRequest<IAreaGroupListReq, IAreaGroupListResp> = params => {
  return request({
    path: '/v1/ra-hall/group/list',
    method: 'GET',
    params,
  })
}

/**
 * 返水明细
 * https://yapi.nbttfc365.com/project/83/interface/api/20077
 */
export const getRebateDetailed: MarkcoinRequest<IARebateDetailedReq, IARebateDetailedResp> = params => {
  return request({
    path: '/v1/rebate/detailed',
    method: 'GET',
    params,
  })
}

/**
 * 返水转账明细
 * https://yapi.nbttfc365.com/project/83/interface/api/20083
 */
export const getRebateTransferDetailed: MarkcoinRequest<IARebateDetailedReq, IARebateDetailedResp> = params => {
  return request({
    path: '/v1/rebate/transferDetailed',
    method: 'GET',
    params,
  })
}

/**
 * 返水佣金
 * https://yapi.nbttfc365.com/project/83/interface/api/20095
 */
export const getRebateBalance: MarkcoinRequest<IARebateBalancedReq, IARebateBalancedResp> = params => {
  return request({
    path: '/v1/rebate/balance',
    method: 'GET',
    params,
  })
}

/**
 * 返水佣金 - 转入我的资产
 * https://yapi.nbttfc365.com/project/83/interface/api/20107
 */
export const getRebateTransfer: MarkcoinRequest<IARebateBalancedReq, IARebateBalancedResp> = params => {
  return request({
    path: '/v1/rebate/transfer',
    method: 'POST',
    params,
  })
}

/**
 * 资金划转 - 单独供应商资金归集
 * https://yapi.nbttfc365.com/project/83/interface/api/20877
 */

export const postBalanceSupplier: MarkcoinRequest<IAbalanceCollectReq, IAbalanceCollectResp> = data => {
  return request({
    path: '/v1/ra-hall/user/balanceCollect/supplier',
    method: 'POST',
    data,
  })
}

/**
 * 获取币种钱包
 * https://yapi.nbttfc365.com/project/44/interface/api/23911
 *  */
export const getMemberWalletCoin: MarkcoinRequest<IAmemberBaseWalletCoinReq, IAmemberBaseWalletCoinResp> = () => {
  return request({
    path: '/v1/member/base/walletCoin',
    method: 'get',
  })
}

/**
 * 设置币种钱包
 * https://yapi.nbttfc365.com/project/44/interface/api/23908
 *  */
export const PostMemberCoinWallet: MarkcoinRequest<IAmemberBaseCoinWalletReq, IAmemberBaseCoinWalletResp> = data => {
  return request({
    path: '/v1/member/base/coinWallet',
    method: 'POST',
    data,
  })
}

/**
 * 获取币种钱包
 * https://yapi.nbttfc365.com/project/44/interface/api/23911
 *  */
export const getMemberCoinWallet: MarkcoinRequest<IAmemberBaseCoinWalletReq, IAmemberBaseCoinWalletResp> = data => {
  return request({
    path: '/v1/member/base/coinWallet',
    method: 'GET',
    data,
  })
}

/**
 * 返水转账明细_v2
 * https://yapi.nbttfc365.com/project/44/interface/api/23908
 *  */
export const getV2RebateTransferDetailed: MarkcoinRequest<IARebateDetailedReq, IARebateDetailedResp> = params => {
  return request({
    path: '/v2/rebate/transferDetailed',
    method: 'get',
    params,
  })
}

/**
 * 未结算游戏记录
 * https://yapi.nbttfc365.com/project/83/interface/api/23978
 *  */
export const getGameRecordUnsettled: MarkcoinRequest<IAreaUserGameRecordReq, IAreaUserGameRecordResp> = params => {
  return request({
    path: '/v1/ra-hall/gameRecord/unsettled',
    method: 'get',
    params,
  })
}
