import { t } from '@lingui/macro'

export enum headerMenuTypeEnum {
  home = 'home',
  trade = 'trade',
}

/** 娱乐区维护状态 */
export enum RecreationPreferenceEnum {
  noShow = 2, // 不显示入口
}

/** 常用弹窗 tab */
export enum commonModalTabEnum {
  /** 功能 */
  function = 'function',
  /** 场馆类型 */
  venueType = 'venueType',
  /** 游戏类型 */
  gameType = 'gameType',
  /** 场馆 or 游戏 */
  // venueOrGame = 'venueOrGame',
}

/** 获取常用弹窗 tab 名称 */
export function getCommonModalTabName(tab: string) {
  return (
    {
      [commonModalTabEnum.function]: t`constants_layout_tuofm7a9_h`,
      [commonModalTabEnum.venueType]: t`constants_layout_n4fqxjjv3a`,
      [commonModalTabEnum.gameType]: t`features_entertainment_area_my_rebate_form_ation_index_wkmiibyge5`,
      // [commonModalTabEnum.venueOrGame]: t`constants_layout_il7km7hsd0`,
    }[tab] || ''
  )
}

/**
 * 左侧功能菜单类型
 */
export enum commonMenuTypeEnum {
  /** 收藏夹 */
  favorite = 'favorite',
  /** 近期游戏记录 */
  recent = 'recent',
  /** 我的投注 */
  myBet = 'myBet',
  /** 游戏供应商 */
  gameSupplier = 'gameSupplier',
  /** 资金划转 */
  fundTransfer = 'fundTransfer',
  /** 划转记录 */
  transferRecord = 'transferRecord',
  /** 我的返水 */
  myRebate = 'myRebate',
  /** 报表统计 */
  report = 'report',
  /** 设置 */
  setting = 'setting',
}

export enum loadedGameStatusEnum {
  notLoaded = 'notLoaded',
  loading = 'loading',
  loaded = 'loaded',
}
