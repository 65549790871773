import { t } from '@lingui/macro'
import styles from './index.module.css'

export enum TabsTypeLogRegisterType {
  log = '1', // 登录
  register = '2', // 注册
  threePartyLogin = '3', // 三方登录
}

export enum TabsResetPasswordStatusType {
  account = '1', // 账号
  passWord = '2', // 密码
  safety = '3', // 安全项
}

type LogTypeTabsType = {
  value: TabsTypeLogRegisterType
  onChange?: (key: TabsTypeLogRegisterType) => void
}

export default function LogTypeTabs(props: LogTypeTabsType) {
  const { value, onChange } = props

  const tabs = [
    {
      key: TabsTypeLogRegisterType.register,
      label: t`user.pageContent.title_02`,
    },
    {
      key: TabsTypeLogRegisterType.log,
      label: t`user.field.reuse_07`,
    },
  ]

  const onTabsChange = (key: TabsTypeLogRegisterType) => {
    onChange?.(key)
  }

  return (
    <div className={styles['log-type-tabs-wrap']}>
      {tabs.map(item => {
        return (
          <div key={item.key} className="item" onClick={() => onTabsChange(item.key)}>
            <label className={value === item.key ? 'item-tabs-text' : ''}>{item.label}</label>
            {value === item.key && <div className="bottom-line" />}
          </div>
        )
      })}
    </div>
  )
}
