import { FundingHistoryTabIdEnum, FundingHistoryTypeEnum } from '@/constants/future/funding-history'
import { OrderTabTypeEnum, EntrustTypeEnum } from '@/constants/order'
import { FinancialRecordLogTypeEnum } from '@/constants/assets'
import { baseLayoutStore } from '@/store/layout'
import { link } from '@/helper/link'
import { getIsLogin } from '@/helper/auth'
import { HistoryTabTypeEnum } from '@/constants/c2c/advertise'
import { FuturesTradeModeEnum } from '@/constants/copy-trading/common'

/** 获取现货订单页面路径 */
export function getSpotOrderPagePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/spot/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}

/** 获取合约订单页面路径 */
export function getFutureOrderPagePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/future/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}
/** 获取带单订单界面路径 */
export function getOrderInterfacePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/takerOrder/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}

// 获取下单页合约订单页面路径
export function getFutureModeOrderPagePath(
  tab: OrderTabTypeEnum,
  entrustType?: EntrustTypeEnum,
  tradeMode?: FuturesTradeModeEnum
) {
  if (tradeMode === FuturesTradeModeEnum.copyTrading) {
    return getOrderInterfacePath(tab, entrustType)
  }
  return getFutureOrderPagePath(tab, entrustType)
}

type IGetFutureFundingRatePagePathParams = {
  type?: FundingHistoryTypeEnum
  tradeId?: any
  tab?: FundingHistoryTabIdEnum
}
/** 获取合约资金费率页面路径 */
export function getFutureFundingRatePagePath({
  type = FundingHistoryTypeEnum.fundingRate,
  tab = FundingHistoryTabIdEnum.usdt,
  tradeId,
}: IGetFutureFundingRatePagePathParams) {
  return `/futures/funding-history/${tab === FundingHistoryTabIdEnum.usdt ? '' : 'quarterly/'}${type}?tradeId=${
    tradeId || ''
  }`
}

/** 联系客服 */
export function linkToCustomerService() {
  const url = baseLayoutStore.getState().layoutProps?.customerJumpUrl
  link(url, { target: true })
}

/**
 * 获取 c2c 中心页面路径
 * @param uid 查看他人 uid 查看自己不传
 * @param type 0 默认进入我的广告单
 * @returns url
 */
export function getC2CCenterPagePath(uid = '', type = 0) {
  let url = '/c2c/center'

  // 使用数组来收集查询参数
  const queryParams: string[] = []

  if (uid) {
    queryParams.push(`uid=${uid}`)
  }

  if (type) {
    queryParams.push(`type=${type}`)
  }

  // 使用 join 连接查询参数
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`
  }

  return url
}

export function linkWithLoginCheck(path: string) {
  if (getIsLogin()) {
    return link(path)
  }

  return link(`login?redirect=${path}`)
}

/** c2c-去充值 */
export function getC2cTopupPageRoutePath() {
  return `/assets/main/deposit`
}

/** c2c-发布广告 */
export function getC2cPostAdvPageRoutePath() {
  return `/c2c/post/adv`
}

/**
 * c2c-商家广告单记录
 * @param type 0 默认进入我的广告单
 */
export function getC2cAdsHistoryPageRoutePath(type?: HistoryTabTypeEnum) {
  let url = '/c2c/ads/history'
  if (type) {
    url = `${url}?&type=${type}`
  }
  return url
}

/** c2c-广告单详情 */
export function getC2cAdvDetailPageRoutePath(id: string) {
  return `/c2c/adv/detail/${id}`
}

/** c2c-成为商家首页 */
export function getC2cMerchantPageRoutePath() {
  return `/c2c/merchant`
}

/** c2c-申请成为商家 */
export function getC2MerchantApplicationPageRoutePath() {
  return `/c2c/merchant/application`
}

/** c2c-历史订单 */
export function getC2cHistoryRecordsPageRoutePath() {
  return `/c2c/orders`
}
/** c2c-订单流程 */
export function getC2cOrderDetailPageRoutePath(id?: string) {
  return `/c2c/orders/detail/${id}`
}

/** c2c-快捷交易 */
export function getC2cOrderShortPageRoutePath() {
  return `/c2c/fast-trade`
}

/** c2c-C2C 交易 */
export function getC2cOrderC2CPageRoutePath() {
  return `/c2c/trade`
}
/** c2c-bid 交易 */
export function getC2cOrderBidPageRoutePath() {
  return `/bid/trade`
}

/** c2c-bid 交易详单 */
export function getC2cOrderBidDetailPageRoutePath(querystring: string) {
  return `/bid/trade/detail?${querystring}`
}

/** 第三方交易 */
export function getThirdPartyPaymentPageRoutePath() {
  return `/c2c/third-party-payment`
}

/** kyc 中心 */
export function getKycPageRoutePath() {
  return '/kyc-authentication-homepage'
}

/** 资产总览 */
export function getAssetsAllRoutePath() {
  return '/assets'
}

/** 查看资产 */
export function getAssetsRoutePath() {
  return '/assets/c2c'
}

/** 查看财务记录 */
export function getFinancialRecordRoutePath() {
  return `/assets/financial-record?type=${FinancialRecordLogTypeEnum.main}`
}

/** maintenance page */
export function getMaintenanceRoutePath() {
  return '/maintenance'
}

export function getC2cFastTradePageRoutePath() {
  return `/c2c/fast-trade`
}

export function getRecreationPageRoutePath() {
  return `/recreation`
}

/** 投注记录 */
export function getRecreationMyBetPageRoutePath() {
  return '/recreation/my-bet'
}

// ====================================================== //
// ===================== vip module ===================== //
// ====================================================== //

export function getVipTierRoutePath() {
  return '/vip/vip-tier'
}

export function getVipCenterRoutePath() {
  return '/vip/vip-center'
}

export function getVipSettingRoutePath() {
  return '/vip/vip-setting'
}

/** c2c-交易 */
export function getC2cTradeRoutePath() {
  return '/c2c/trade'
}

/** c2c-提币 */
export function getMaiWithdrawRoutePath() {
  return '/assets/main/withdraw'
}

/** 收付款管理 */
export function getPaymentManagementRoutePath() {
  return '/fiat/payment'
}

/** KYC 认证 */
export function getKycManagementRoutePath() {
  return '/kyc-authentication-homepage'
}

/** 登陆页 */
export function getLoginPath() {
  return '/login'
}

/** 用户安全验证中心 */
export function getPersonalAccountSecurityPath() {
  return '/personal-center/account-security'
}

/** 偏好设置 */
export function getPersonalCenterSettingsSecurityPath() {
  return '/personal-center/settings'
}

/** API 管理 */
export function getPersonalCenterSettingsApiSecurityPath() {
  return '/personal-center/settings/api'
}

/** 用户安全验证中心 */
export function getSafetyVerificationPath(searchOriginal?, redirect?) {
  if (searchOriginal || redirect) {
    return `/safety-verification${searchOriginal || `?redirect=${redirect}`}`
  }
  return '/safety-verification'
}

/** 注册认证 */
export function getRegisterVerificationPath(method) {
  return `/register/verification?type=${method}`
}

/** 重置安全项验证 */
export function getSafetyicationPath() {
  return `/safety-items`
}

/** 重置密码 - 人工 */
export function getResetPasswordPath(type?: string) {
  return `/reset-artificial?popup=${type}`
}

/** 安全项验证 */
export function getRestSafetyicationPath() {
  return '/rest-safety-verification'
}

/** 邀请返佣 */
export function getAgentPath() {
  return '/agent'
}

/** 联盟中心 */
export function getAgentCenterPath() {
  return '/agent/agency-center'
}

/** 福利中心 */
export function getWelfareCenterPath() {
  return '/welfare-center'
}

/** 重置密码 */
export function getRetrieveResetPasswordPath(artificial = false) {
  if (artificial) {
    return '/retrieve/reset-password?artificial=true'
  }
  return `/retrieve/reset-password`
}
