import cacheUtils from 'store'

export const userInfo = 'USER_INFO'

export function getUserInfo() {
  return cacheUtils.get(userInfo)
}

export function setUserInfo(val) {
  return cacheUtils.set(userInfo, val)
}

export function removeUserInfo() {
  return cacheUtils.set(userInfo, '')
}

/** C2C 公有化用户信息 */
export const C2C_USER_INFO = 'C2C_USER_INFO'

export function getC2CUserInfoCache() {
  return cacheUtils.get(C2C_USER_INFO)
}

export function setC2CUserInfoCache(data) {
  return cacheUtils.set(C2C_USER_INFO, data)
}

/** 重置密码相关数据 */
export const resetPassword = 'REST_PASSWORD'

export function setResetPasswordToken(val: string) {
  return cacheUtils.set(resetPassword, val)
}

export function getResetPasswordToken() {
  return cacheUtils.get(resetPassword)
}

/** 注册输入账号相关数据 */
export const checkParams = 'CHECK_PARAMS'

export function setCheckParamsToken(val: string) {
  return cacheUtils.set(checkParams, val)
}

export function getCheckParamsToken() {
  return cacheUtils.get(checkParams)
}

/** 登陆，试玩之后获取的控制台配置安全项 */
export const merchantSettings = 'MERCHANT_SETTINGS'

export function setMerchantSettingsToken(val) {
  return cacheUtils.set(merchantSettings, val)
}

export function getMerchantSettingsToken() {
  return cacheUtils.get(merchantSettings)
}
