/**
 * showStatusCd enum for display logic
 */
export enum showStatusEnum {
  login = 1,
  notLogin = 2,
  both = 3,
}

export enum enabledStatusEnum {
  isEnabled = 1,
  isDisabled = 2,
}

/**
 * 菜单宽度
 */
export enum MenuWidthEnum {
  /** 展开状态 */
  open = 240,
  /** 收起状态 */
  close = 60,
}
