import { t } from '@lingui/macro'
/**
 * 设置
 */

/** 设置菜单 */
export enum SettingsMenuEnum {
  /** 隐私 */
  Privacy = 'privacy',
  /** 钱包模式 */
  WalletMode = 'wallet-mode',
  /** 偏好 */
  Preference = 'preference',
}

/**
 * 获取设置菜单名称
 */
export function getSettingsMenuName(type: string) {
  return {
    [SettingsMenuEnum.Privacy]: t`features_settings_privacy_index_xlfeyv1t9u`,
    [SettingsMenuEnum.WalletMode]: t`constants_settings_xj2vrss5_k`,
    [SettingsMenuEnum.Preference]: t`features_settings_preference_index_ocka8i3aeh`,
  }[type]
}

/**
 * 隐私设置选项
 */
export enum PrivacySettingEnum {
  /** 隐藏 */
  enable = 'enable',
  /** 不隐藏 */
  disable = 'disable',
}

/**
 * 钱包模式
 */
export enum WalletModeEnum {
  /** 转账模式 */
  transfer = 'fund_transfer',
  /** 单一钱包 */
  single = 'single_wallet',
}

/**
 * 获取切换成功模式文案
 */
export function getSwitchWalletModeSuccessText(type: string) {
  return {
    [WalletModeEnum.transfer]: t`constants_settings_1g8qcu093f`,
    [WalletModeEnum.single]: t`features_settings_wallet_mode_index_3gprg9k0rd`,
  }[type]
}

/**
 * 钱包菜单key
 */
export const WalletModeKey = 'WalletMode'
