import { languageRoutes } from '@/constants/i18n'
import { maintenanceApiResCode } from '@/constants/maintenance'
import { baseUrl, envIsServer } from '@/helper/env'
import { handleRiskErrorCode } from '@/helper/risk-control'
import { link } from '@/helper/link'
import { BaseMarkcoinResponse, MarkcoinResponse } from '@/plugins/request'
import { baseUserStore } from '@/store/user'
import { baseCommonStore } from '@/store/common'
import { t } from '@lingui/macro'
import { Message } from '@nbit/arco'
import { AxiosResponse } from 'axios'
import { MergeModeLoginInvalidPopUp } from '@/features/user/utils/common'
import { CopyTradingApiErrorCode } from '@/constants/copy-trading/common'
import { AssetApiErrorCode, ErrorTypeEnum } from '@/constants/assets'

const authErrorCode = [401]
/**
 * 10000065 平台内提币用：UID 错误，请检查后重试（表单校验，不弹框错误）
 * 10080003 登出 token 错误码
 * 10096004 合约组不存在
 * 10106004 已刷新价格，请重新购买
 * 10106005 otc 个人交易已经超过限额
 * 10151001 卡券中心领券系统错误
 * 10151002 卡券中心领券参数错误
 * 10151003 卡券中心领券优惠券不存在
 * 10151004 卡券中心领券优惠券已过期
 * 10151005 卡券中心领券超过优惠券领取上限
 * 10151006 卡券中心优惠券已领完
 * 10151007 卡券中心优惠券优惠券领取失败
 * 100000000000001 bff 请求本地资源文件错误码
 */
const passBusinessCode = [
  1000, // 风控错误码
  10030,
  10000059,
  10000065,
  10080003,
  10106004,
  10106001,
  10106003,
  10106005,
  10109014,
  10151001,
  10151002,
  10151003,
  10151004,
  10151005,
  10151006,
  10151007,
  AssetApiErrorCode.noExistGroupId,
  // CopyTradingApiErrorCode.projectFollowOverLimit,
  // CopyTradingApiErrorCode.projectNotExists,
  CopyTradingApiErrorCode.projectIsNotFollow,
  100000000000001,
]
const passRoutes = ['/futures/', '/login', '/register', '/retrieve', '/safety-verification']
// 自行处理的错误码，不会弹窗提示
const selfHandleErrorCodes = [maintenanceApiResCode]

let mergeModePopUpShow = false

/** 处理状态码，并提示用户 */
const handleErrorCode = async (code: number, msg: string, errorMessage?: string | undefined) => {
  if (envIsServer) {
    return
  }

  const isAuthError = authErrorCode.includes(code)
  const isServerError = (code === 500 && msg === 'Network Error') || (code === 500 && msg?.includes('timeout of'))

  /** 用户信息失效处理 */
  if (isAuthError) {
    await baseUserStore.getState().clearUserCacheData()
    const urlPathname = location.pathname
    const { isMergeMode } = baseCommonStore.getState()

    if (isMergeMode) {
      if (mergeModePopUpShow) return

      MergeModeLoginInvalidPopUp()

      mergeModePopUpShow = true
      return
    }

    /** 运行某些页面未登录访问、用户信息失效访问的处理 */
    if (!passRoutes.some(route => urlPathname.includes(route))) {
      Message.error({
        content: msg || t`plugins_request_interceptors_response_response_handler_2763`,
        id: ErrorTypeEnum.authError,
      })
      const language = languageRoutes.find(route => urlPathname.includes(route))
      const redirect = urlPathname.replace(language || '', '')
      link(`/login?redirect=${redirect}`)
      return
    }
    Message.error({
      content: msg,
      id: ErrorTypeEnum.authError,
    })
    return
  }

  if (isServerError) {
    const content = t`plugins_request_5101063`
    Message.error({
      content,
      id: ErrorTypeEnum.serverError,
    })
  }

  if (!isAuthError && !isServerError) {
    const content = errorMessage || msg
    content &&
      Message.error({
        content,
        id: ErrorTypeEnum.uncategorizedError,
      })
  }
}

const onFulfilled: (input: any) => Promise<any> = async (response: AxiosResponse<BaseMarkcoinResponse<any>>) => {
  let resData: MarkcoinResponse
  const res = response.data
  const msg = res.message!
  const config = response.config

  // todo 本地测试用，后面删除
  // const defaultRiskControl = { code: RiskControlTypeEnum?.riskControlLv1, msg: '当前地区暂未开放，无法访问' }
  const riskControl = response?.data?.riskControl

  // 风控处理
  await handleRiskErrorCode(riskControl)

  // TODO: 现在是 yapi mock 暂未约束 code 码
  if (res.code === 200 || baseUrl.includes('yapi')) {
    resData = {
      isOk: true,
      data: res.data,
      message: msg,
    }

    // @ts-ignore
    if (config.needAllRes) {
      resData = { ...resData, ...res }
    }
    return resData
  }

  // 其它未知错误，原样返回
  // 过滤业务代码
  const isPassBusinessCode = passBusinessCode.includes(res.code)
  if (!isPassBusinessCode && !selfHandleErrorCodes.includes(res.code)) {
    // 用户登录失效
    handleErrorCode(res.code, msg)
  }
  resData = {
    isOk: false,
    data: res.data,
    message: msg,
    code: res.code,
  }
  return Promise.resolve(resData)
}

const onRejected = error => {
  console.debug('[responseHandler interceptor error ]', error)
  let resData: MarkcoinResponse
  const response = error?.response
  const msg = response?.data?.message || error?.toJSON?.()?.message
  const code = response?.data?.code || response?.status || 500
  const errorMessage = error?.config?.errorMessage
  const riskControl = response?.data?.riskControl

  // 风控处理
  handleRiskErrorCode(riskControl)

  // 用户登录失效
  handleErrorCode(code, msg, errorMessage)

  resData = {
    isOk: false,
    data: '',
    message: msg,
    code: -1,
  }
  return Promise.resolve(resData)
}

export default {
  onFulfilled,
  onRejected,
}
