/** 与业务无关的 基础模块 */
/** */
/** 主题 */
export enum ThemeEnum {
  light = 'light',
  dark = 'dark',
}

export enum ThemeBusinessEnum {
  chainstar = 'chainstar',
  default = '',
}

export enum ColorPlateEnum {
  binance = 'binance',
  okx = 'okx',
  kucoin = 'kucoin',
  default = '',
}

export enum InitThemeColor {
  'cs' = 'chainstar',
  'mk' = 'monkey',
  'ok' = 'okx',
  'bn' = 'binance',
  'kc' = 'kucoin',
  '2z' = '2z',
}

export const ThemeChartMap = {
  light: 'Light',
  dark: 'Dark',
}

export const pageOmitKeys = [
  '_serverFiles',
  '_parseUrl',
  '_pageRoutes',
  '_pageIsomorphicFileDefault',
  '_pageIsomorphicFile',
  '_pageContextRetrievedFromServer',
  '_onBeforeRouteHook',
  '_onBeforeRouteHook',
  '_objectCreatedByVitePluginSsr',
  '_isFirstRender',
  '_comesDirectlyFromServer',
  '_baseUrl',
  '_allPageIds',
  '_allPageFiles',
  'exports',
  'Page',
  '_baseAssets',
  '_getPageAssets',
  '_isPageContextRequest',
  '_isPreRendering',
  '_pageClientPath',
  '_pageId',
  '_pageServerFile',
  '_pageServerFileDefault',
  '_passToClient',
  //
  '_pageFilesLoaded',
  '_pageFilesAll',
  '_pageFilesAll',
  'pageExports',
  'exportsAll',
]

export enum envBaseUrl {
  production = 'h5',
  development = 'h5-staging',
  stress = 'h5-staging',
  test = 'h5-staging',
  dev = 'h5-dev',
}

// 空状态占位符
export enum EmptyStatePlaceholder {
  doubleLine = '--',
}

/** 错误页面码 */
export enum ErrorPageCodeEnum {
  is403 = 403,
  is404 = 404,
}

/** todo: 菜鸟后端不知道什么为 boolean 类型的，因此有了次枚举 */
export enum BooleanEnum {
  isTrue = 'true',
  isFalse = 'false',
}
