/**
 * 设置
 */
import produce from 'immer'
import { create } from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import { subscribeWithSelector } from 'zustand/middleware'
import { getRaSettingCache, setRaSettingCache } from '@/helper/cache'
import { PrivacySettingEnum, WalletModeEnum } from '@/constants/settings'

type IStore = ReturnType<typeof getStore>

const defaultRaSettings = {
  /** 是否开启隐身 */
  hideStatus: PrivacySettingEnum.disable,
  /** 隐藏所有统计数据 */
  hideStatistical: PrivacySettingEnum.disable,
  /** 隐藏所有竞赛记录 */
  hideCompetition: PrivacySettingEnum.disable,
  /** 钱包模式 */
  walletModeCd: WalletModeEnum.single,
}

function getStore(set, get) {
  return {
    /** 娱乐区设置 */
    raSettings: !getRaSettingCache() ? defaultRaSettings : { ...defaultRaSettings, ...getRaSettingCache() },
    updateRaSettings: values => {
      set((store: IStore) => {
        return produce(store, _store => {
          const newARaSettings = { ..._store.raSettings, ...values }

          _store.raSettings = newARaSettings
          setRaSettingCache(newARaSettings)
        })
      })
    },
  }
}

const baseRaSettingsStore = create(subscribeWithSelector(getStore))

const useRaSettingsStore = createTrackedSelector(baseRaSettingsStore)

export { useRaSettingsStore, baseRaSettingsStore }
