import { businessId, newbitEnv } from '@/helper/env'
import { fastUrlUtils, envUtils } from '@nbit/utils'

// 维护状态 no_show 不显示，no_in 不让进，normal 取消维护
export enum MaintenanceEnum {
  no_show = 'no_show',
  normal = 'normal',
  no_in = 'no_in',
}

const { EnvTypesEnum, MerchantIDEnum, MerchantID } = envUtils
const { getFastUrl } = fastUrlUtils
export const maintenanceApiResCode = 9999

export const getS3MaintenanceApiUrl = () => {
  const mode = newbitEnv
  switch (mode) {
    case EnvTypesEnum.production:
      return getFastUrl(
        `${MerchantID[MerchantIDEnum.common].fastProdS3Domain}/${businessId}_maintenanceConfig.json`,
        MerchantIDEnum.common
      )
    case EnvTypesEnum.dev:
      return getFastUrl(
        `${MerchantID[MerchantIDEnum.common].fastDevS3Domain}/${businessId}_maintenanceConfig.json`,
        MerchantIDEnum.common
      )
    case EnvTypesEnum.test:
      return getFastUrl(
        `${MerchantID[MerchantIDEnum.common].fastTestS3Domain}/${businessId}_maintenanceConfig.json`,
        MerchantIDEnum.common
      )
    default:
      return getFastUrl(
        `${MerchantID[MerchantIDEnum.common].fastTestS3Domain}/${businessId}_maintenanceConfig.json`,
        MerchantIDEnum.common
      )
  }
}
