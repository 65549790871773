import { I18nsEnum } from '@/constants/i18n'
import { baseCommonStore } from '@/store/common'
import { navigate } from 'vite-plugin-ssr/client/router'
import { IsWhiteListRoute, getMergeModeStatus } from '@/features/user/utils/common'
import { TabsTypeLogRegisterType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { baseUserStore } from '@/store/user'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { isAbsoluteUrl } from './common'
import { removeLocale } from './i18n'
import { IsModuleWhiteListRoute } from './module-config'

export interface ILinkConfig {
  /** 保持登录位置 */
  keepScrollPosition?: boolean | undefined
  /** 不要在浏览器的历史记录中创建新条目；新 URL 将替换当前 URL（这有效地从历史记录中删除当前 URL */
  overwriteLastHistoryEntry?: boolean | undefined
  /** 打开新页面 */
  target?: boolean
}
export const link = (url?: string, goConfig?: ILinkConfig) => {
  const { locale } = baseCommonStore.getState()
  const { setLogOrRegister, setIsResetPassword, setLogRegisterVisible } = baseUserStore.getState()
  const isMergeMode = getMergeModeStatus()
  const lang = locale === I18nsEnum['en-US'] ? '' : `/${locale}`
  const sanitisedUrl = removeLocale(url) || ''
  let _url = `${lang}${sanitisedUrl}`

  // 防止后端设置为 null
  if (!url) _url = ''
  if (isAbsoluteUrl(url)) {
    _url = url as string
    return window.open(_url, 'target')
  }

  /** 融合模式判断路由地址是否为黑名单地址 */
  if (isMergeMode && sanitisedUrl) {
    const isTrue = IsWhiteListRoute(sanitisedUrl)
    if (!isTrue) return
  }

  if (!isMergeMode && goConfig?.target) {
    /** 普通模式 - 路由是否白名单地址 */
    if (sanitisedUrl && !IsModuleWhiteListRoute(sanitisedUrl)) {
      return
    }
    return window.open(_url, 'target')
  }

  let isNewUrl = false
  let handleLink = _url.replace(/\/login|\/register|\/retrieve/, function (match) {
    // eslint-disable-next-line no-template-curly-in-string
    if (systemThemeColor !== InitThemeColor['2z']) return match
    if (match === '/login') {
      setLogRegisterVisible(true)
      setLogOrRegister(TabsTypeLogRegisterType.log)
      isNewUrl = true
      return '/'
    }
    if (match === '/register') {
      setLogRegisterVisible(true)
      setLogOrRegister(TabsTypeLogRegisterType.register)
      isNewUrl = true
      return '/'
    }
    if (match === '/retrieve') {
      setIsResetPassword(true)
      setLogRegisterVisible(true)
      isNewUrl = true
      return '/'
    }
    return match
  })

  if (isNewUrl) return null

  return navigate(handleLink, {
    overwriteLastHistoryEntry: !!goConfig?.overwriteLastHistoryEntry,
    keepScrollPosition: !!goConfig?.keepScrollPosition,
  })
}
